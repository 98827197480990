@font-face {
    font-family: "League Spartan";
    src: url("./LeagueSpartan-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "PT Sans";
    src: url("./PTC55F.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Cyrillic";
    src: url("./coolvetica.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
// @font-face {
//     font-family: 'Neue Machina';
//     src: url('./PPNeueMachina-Regular.otf') format('opentype');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Neue Machina';
//     src: url('./PPNeueMachina-Ultrabold.otf') format('opentype');
//     font-weight: bold;
//     font-style: normal;
// }
