.MuiAutocomplete-root {
  margin-top: 0 !important;
}
.MuiAutocomplete-popper {
  z-index: 10001 !important;
  background: #202330;
  li {
    background: #202330;
    color: white;
    &:hover {
      background: #1f293c;
    }
  }
  .MuiAutocomplete-listbox {
    background: #202330;
  }
}
